import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit, OnDestroy {
  @ViewChild('claimModal') claimModal: ElementRef;

  myNFTs;
  removeClaimAddress;
  removeClaimRarity;
  myPendingNFTs;
  checkConnection;
  loading: boolean = false;

  constructor(
    private readonly walletService: WalletService,
    private readonly aloha: AlohaService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.checkConnection = setInterval(async () => {
      if (await this.walletService.getAccount()) {
        this.loadUserData();
        clearInterval(this.checkConnection);
      }
    }, 1000);
    if (await this.walletService.getAccount()) {
      clearInterval(this.checkConnection);
      await this.loadUserData();
    }
    this.loading = false;
  }

  private async loadUserData(): Promise<void> {
    this.loading = true;
    this.myNFTs = await this.aloha.alohaNFTs();
    this.myPendingNFTs = await this.aloha.pendingAlohaNFTs();
    this.loading = false;
  }

  async claim(address: string, rarity: number): Promise<void> {
    this.loading = true;
    await this.aloha.withdraw(address, rarity);
    this.loading = false;
    this.ngOnInit();
  }

  setRemoveClaim(address, rarity) {
    this.removeClaimAddress = address;
    this.removeClaimRarity = rarity;
  }

  async forceClaim(address: string, rarity: number): Promise<void> {
    this.loading = true;
    this.claimModal.nativeElement.click();
    await this.aloha.forceWithdraw(address, rarity);
    this.loading = false;
    this.ngOnInit();
  }

  async ngOnDestroy(): Promise<void> {
    clearInterval(this.checkConnection);
  }

  hoursTo(timestamp): string {
    const date1 = new Date();
    const date2 = new Date(timestamp * 1000);

    if (date1 > date2) {
      return 'CLAIM';
    }

    const diffInSeconds = Math.abs(date1.getTime() - date2.getTime()) / 1000;
    const minutes = Math.ceil(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days + ' days left';
    }
    if (hours > 0) {
      return hours + ' hours left';
    }

    return minutes + ' minutes left';
  }
}
