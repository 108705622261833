import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlohaService } from 'src/app/services/aloha.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @ViewChild('addressTo') addressTo: ElementRef;

  id = '...';
  owner = '';
  rarity = '';
  image = '';
  background = '';
  serie = '';
  account;
  approved: boolean = false;
  loading: boolean = false;
  migrated: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly aloha: AlohaService,
    private readonly walletService: WalletService
  ) {}

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData(this.id);
  }

  private async loadData(id: string): Promise<void> {
    this.loading = true;
    this.account = await this.walletService.getAccount();
    this.approved = await this.aloha.isApprovedForAll();
    const nft = await this.aloha.getNftById(parseInt(id, undefined));
    this.owner = nft.owner;
    this.rarity = nft.rarity;
    this.image = nft.image;
    this.serie = this.aloha.getSerieByImage(nft.image);
    this.background = nft.background;
    this.migrated = this.aloha.alohaNFTv2Address == this.owner;
    this.loading = false;
  }

  async transfer(tokenId): Promise<void> {
    await this.aloha.transfer(this.addressTo.nativeElement.value, tokenId);
  }

  async approveAll(): Promise<void> {
    this.loading = true;
    await this.aloha.setApprovedForAll();
    this.loadData(this.id);
    this.loading = false;
  }

  async migrateNFT(id: string): Promise<void> {
    this.loading = true;
    await this.aloha.migrateNFT(id);
    this.loadData(this.id);
    this.loading = false;
  }
}
