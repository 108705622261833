<div class="slim-mainpanel">
  <div class="container">
    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>CLAIM PENDING</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="row justify-content-md-center pd-t-20">
      <div class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!loading" class="row justify-content-md-center pd-t-20">
      <div
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
        *ngFor="let myPendingNFT of myPendingNFTs"
      >
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-12">
            <app-aloha-card
              image="{{ myPendingNFT.image }}"
              rarity="{{ myPendingNFT.rarity }}"
              background="{{ myPendingNFT.background }}"
            ></app-aloha-card>
          </div>
          <div class="col-md-8 pd-t-40">
            <div class="btn-demo">
              <button
                class="btn btn-oblong btn-aloha btn-block"
                *ngIf="hoursTo(myPendingNFT.endDate) == 'CLAIM'"
                (click)="claim(myPendingNFT.address, myPendingNFT.rarity)"
              >
                CLAIM NOW
              </button>
              <button
                class="btn btn-oblong btn-aloha btn-block"
                *ngIf="hoursTo(myPendingNFT.endDate) != 'CLAIM'"
                data-toggle="modal"
                data-target="#claimModal"
                (click)="
                  setRemoveClaim(myPendingNFT.address, myPendingNFT.rarity)
                "
              >
                {{ hoursTo(myPendingNFT.endDate) }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="myPendingNFTs.length == 0"
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
      >
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-12" [routerLink]="['/']">
            <img
              class="aloha-card pointer"
              src="assets/img/cards/add.png"
              alt="img-mobile"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>MY NFTs COLLECTION</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="row justify-content-md-center pd-t-20">
      <div class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!loading" class="row justify-content-md-center pd-t-20 pd-b-50">
      <div
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
        *ngFor="let myNFT of myNFTs"
      >
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-12">
            <app-aloha-card
              id="{{ myNFT.id }}"
              image="{{ myNFT.image }}"
              rarity="{{ myNFT.rarity }}"
              background="{{ myNFT.background }}"
            ></app-aloha-card>
          </div>
        </div>
      </div>

      <div
        *ngIf="myNFTs.length == 0"
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
      >
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-12" [routerLink]="['/']">
            <img
              class="aloha-card pointer"
              src="assets/img/cards/add.png"
              alt="img-mobile"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #claimModal id="claimModal" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content tx-size-sm">
      <div class="modal-body tx-center pd-y-20 pd-x-20">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <i
          class="icon icon ion-ios-close-outline tx-100 tx-danger lh-1 mg-t-20 d-inline-block"
        ></i>
        <h4 class="tx-danger mg-b-20">Do you want to cancel the stake?</h4>
        <p class="mg-b-20 mg-x-20">
          This action will return your tokens back and you will lose this nft. Are you sure?
        </p>
        <button
          type="button"
          class="btn btn-danger pd-x-25"
          (click)="forceClaim(removeClaimAddress, removeClaimRarity)"
        >
          Stop Stake
        </button>
      </div>
    </div>
  </div>
</div>
