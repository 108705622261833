import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollectionComponent } from './components/collection/collection.component';
import { ExploreComponent } from './components/explore/explore.component';
import { PoolComponent } from './components/pool/pool.component';
import { StakeComponent } from './components/stake/stake.component';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { AlohaCardComponent } from './components/aloha-card/aloha-card.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  declarations: [
    AppComponent,
    NoCommaPipe,
    CollectionComponent,
    StakeComponent,
    ExploreComponent,
    PoolComponent,
    AlohaCardComponent,
    CardComponent,
  ],
  imports: [CommonModule, BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
