<div class="slim-mainpanel pd-b-40">
  <div class="container">
    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>EXPLORE LAST MINTED NFTs</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center pd-t-20">
      <div *ngIf="loading" class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
        *ngFor="let nft of lastNFTs"
      >
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            id="{{ nft.id }}"
            image="{{ nft.image }}"
            rarity="{{ nft.rarity }}"
            background="{{ nft.background }}"
          ></app-aloha-card>
        </div>
      </div>
    </div>
  </div>
</div>
