import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardComponent } from './components/card/card.component';
import { CollectionComponent } from './components/collection/collection.component';
import { ExploreComponent } from './components/explore/explore.component';
import { PoolComponent } from './components/pool/pool.component';
import { StakeComponent } from './components/stake/stake.component';

const routes: Routes = [
  {
    path: 'explore',
    component: ExploreComponent,
  },
  {
    path: 'pool/:rarity',
    component: PoolComponent,
  },
  {
    path: 'card/:id',
    component: CardComponent,
  },
  {
    path: '',
    component: StakeComponent,
  },
  {
    path: 'collection',
    component: CollectionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
