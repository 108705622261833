import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { WalletService } from './wallet.service';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AlohaService {
  MAX_INT = BigInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
  );

  maxRarity = 3;
  alohaTokenAddress = '0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe';
  alohaNFTAddress = '0x9ad18b012bf83e3b0ccabcfa3d74a9bfb6889c77';
  alohaNFTv2Address = '0x524833d8b9C2194Ead830Fd205E8fAEd9801E776';
  alohaStakingAddress = '0xa1784f009d990d9b33fac0f5461cac9bcb21d827';

  currentAccount: string;

  constructor(private readonly wallet: WalletService) {}

  async alohaNFTs(): Promise<object> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.alohaNFTAddress
    );
    const totalSupply = await alohaNFT.methods.totalSupply().call();
    const myBalance = await alohaNFT.methods.balanceOf(account).call();
    const myNFTs = [];

    for (let tokenId = 1; tokenId <= totalSupply; tokenId++) {
      const tokenOwner = await alohaNFT.methods.ownerOf(tokenId).call();

      if (tokenOwner.toUpperCase() === account.toUpperCase()) {
        myNFTs.push({
          id: tokenId,
          image: await alohaNFT.methods.tokenImage(tokenId).call(),
          background: await alohaNFT.methods.tokenBackground(tokenId).call(),
          rarity: await alohaNFT.methods.tokenRarity(tokenId).call(),
          uri: await alohaNFT.methods.tokenRarity(tokenId).call(),
        });
      }

      if (myNFTs.length >= myBalance) {
        break;
      }
    }

    return myNFTs;
  }

  getSerieByImage(image: string): string {
    return parseInt(image, undefined) <= 6 ? '1' : '2';
  }

  async getNftById(
    id: number
  ): Promise<{
    owner: string;
    image: string;
    background: string;
    rarity: string;
  }> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.alohaNFTAddress
    );
    return {
      owner: await alohaNFT.methods.ownerOf(id).call(),
      image: await alohaNFT.methods.tokenImage(id).call(),
      background: await alohaNFT.methods.tokenBackground(id).call(),
      rarity: await alohaNFT.methods.tokenRarity(id).call(),
    };
  }

  async lastAlohaNFTs(limit: number): Promise<object> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.alohaNFTAddress
    );
    const totalSupply = parseInt(
      await alohaNFT.methods.totalSupply().call(),
      undefined
    );
    const myNFTs = [];

    for (let tokenId = totalSupply; tokenId >= totalSupply - limit; tokenId--) {
      if (tokenId === 0) {
        break;
      }

      myNFTs.push({
        id: tokenId,
        image: await alohaNFT.methods.tokenImage(tokenId).call(),
        background: await alohaNFT.methods.tokenBackground(tokenId).call(),
        rarity: await alohaNFT.methods.tokenRarity(tokenId).call(),
        uri: await alohaNFT.methods.tokenRarity(tokenId).call(),
      });
    }

    return myNFTs;
  }

  async getTotalBackgrounds(): Promise<number[]> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    const total = await alohaStaking.methods.backgrounds().call();
    const response = [];
    for (let index = 1; index <= total; index++) {
      response.push(index);
    }

    return response;
  }

  async isApprovedForAll(): Promise<boolean> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const address = await this.getAccount();
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.alohaNFTAddress
    );

    return await alohaNFT.methods
      .isApprovedForAll(address, this.alohaNFTv2Address)
      .call();
  }

  async setApprovedForAll(): Promise<boolean> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const address = await this.getAccount();
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.alohaNFTAddress
    );

    return await alohaNFT.methods
      .setApprovalForAll(this.alohaNFTv2Address, true)
      .send({ from: address });
  }

  async migrateNFT(id: string): Promise<boolean> {
    const alohaNFTV2Abi = require('../../assets/abis/AlohaNFTV2.json');
    const address = await this.getAccount();
    const alohaNFTV2 = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTV2Abi,
      this.alohaNFTv2Address
    );

    return await alohaNFTV2.methods.migrateToken(id).send({ from: address });
  }

  async getTotalImages(rarity: number): Promise<number> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );
    return await alohaStaking.methods.rarityByImagesTotal(rarity).call();
  }

  async getImages(rarity: number): Promise<number[]> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    const totalImages = await this.getTotalImages(rarity);
    const images = [];
    for (let index = 0; index < totalImages; index++) {
      const image = await alohaStaking.methods
        .rarityByImages(rarity, index)
        .call();

      if (image === '0') {
        break;
      }

      images.push(Number(image));
    }

    return images;
  }

  async getPools(rarity: number): Promise<object> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    const pools = [];
    for (const poolAddress of environment.poolsAddresses) {
      const pool = await alohaStaking.methods
        .poolsMap(poolAddress, rarity)
        .call();

      if (pool.alohaAmount === '0') {
        continue;
      }

      let erc20Token = null;
      if (pool.erc20Amount > 0) {
        const erc20Abi = require('../../assets/abis/AlohaToken.json');
        erc20Token = new (this.wallet.getWeb3().eth.Contract)(
          erc20Abi,
          poolAddress
        );
      }

      pools.push({
        address: poolAddress,
        alohaAmount: this.wallet.getWeb3().utils.fromWei(pool.alohaAmount),
        erc20Symbol:
          pool.erc20Amount > 0
            ? await erc20Token.methods.symbol().call()
            : null,
        erc20Amount:
          pool.erc20Amount > 0
            ? pool.erc20Amount /
              10 ** (await erc20Token.methods.decimals().call())
            : null,
        duration: pool.duration,
        rarity: pool.rarity,
      });
    }

    return pools;
  }

  async pendingAlohaNFTs(): Promise<object> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    const myPendingNFTs = [];
    for (const poolAddress of environment.poolsAddresses) {
      for (let rarity = 1; rarity <= this.maxRarity; rarity++) {
        const staking = await alohaStaking.methods
          .stakingsMap(account, poolAddress, rarity)
          .call();

        if (staking.tokenImage === '0') {
          continue;
        }

        myPendingNFTs.push({
          id: null,
          image: staking.tokenImage,
          background: staking.tokenBackground,
          rarity,
          uri: null,
          endDate: staking.endDate,
          address: poolAddress,
        });
      }
    }
    return myPendingNFTs;
  }

  async approve(address: string): Promise<void> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      address
    );

    await erc20Token.methods
      .approve(this.alohaStakingAddress, this.MAX_INT)
      .send({ from: account });
  }

  async transfer(address: string, tokenId: string): Promise<void> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.alohaNFTAddress
    );

    await alohaNFT.methods
      .transferFrom(account, address, tokenId)
      .send({ from: account });
  }

  async withdraw(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    if (address === environment.poolsAddresses[0]) {
      await alohaStaking.methods.simpleWithdraw(rarity).send({ from: account });
    } else {
      await alohaStaking.methods
        .pairWithdraw(address, rarity)
        .send({ from: account });
    }
  }

  async forceWithdraw(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    if (address === environment.poolsAddresses[0]) {
      await alohaStaking.methods
        .forceSimpleWithdraw(rarity)
        .send({ from: account });
    } else {
      await alohaStaking.methods
        .forcePairWithdraw(address, rarity)
        .send({ from: account });
    }
  }

  async allowedAloha(amount: number): Promise<boolean> {
    return this.allowedToken(this.alohaTokenAddress, amount);
  }

  async allowedToken(tokenAddress: string, amount: number): Promise<boolean> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      tokenAddress
    );

    const decimals = await erc20Token.methods.decimals().call();

    const allowance = await erc20Token.methods
      .allowance(account, this.alohaStakingAddress)
      .call();

    // tslint:disable-next-line: triple-equals
    return allowance >= amount * 10 ** decimals;
  }

  async inStake(address: string, rarity: number): Promise<boolean> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    const staking = await alohaStaking.methods
      .stakingsMap(account, address, rarity)
      .call();

    // tslint:disable-next-line: triple-equals
    return !(staking.endDate === '0');
  }

  async simpleStake(rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    await alohaStaking.methods.simpleStake(rarity).send({ from: account });
  }

  async pairStake(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.alohaStakingAddress
    );

    await alohaStaking.methods
      .pairStake(address, rarity)
      .send({ from: account });
  }

  private async getAccount(): Promise<string | null> {
    if (!this.currentAccount) {
      this.currentAccount = await this.wallet.getAccount();
    }
    return this.currentAccount;
  }
}
