<div *ngIf="loading" class="slim-mainpanel pd-b-40">
  <div class="container">
    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span *ngIf="firstLoading">LOADING POOL DETAILS...</span>
            <span *ngIf="blockchainLoading"
              >WAITING TRANSACTION CONFIRMATION...</span
            >
          </div>

          <div class="col-md-12 col-xl-12">
            <div class="d-flex ht-300 pos-relative align-items-center">
              <div class="sk-circle">
                <div class="sk-circle1 sk-child"></div>
                <div class="sk-circle2 sk-child"></div>
                <div class="sk-circle3 sk-child"></div>
                <div class="sk-circle4 sk-child"></div>
                <div class="sk-circle5 sk-child"></div>
                <div class="sk-circle6 sk-child"></div>
                <div class="sk-circle7 sk-child"></div>
                <div class="sk-circle8 sk-child"></div>
                <div class="sk-circle9 sk-child"></div>
                <div class="sk-circle10 sk-child"></div>
                <div class="sk-circle11 sk-child"></div>
                <div class="sk-circle12 sk-child"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading" class="slim-mainpanel">
  <div class="container" *ngFor="let pool of pools; index as i">
    <div class="row row-xs justify-content-md-center" *ngIf="i == 0">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              *ngIf="rarity == 3"
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              *ngIf="rarity == 2"
              class="hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              *ngIf="rarity == 1"
              class="hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div
            [ngClass]="{
              'title-vip txt-line-vip': rarity == 3,
              'title-ultrarare txt-line-ultrarare': rarity == 2,
              'title-rare txt-line-rare': rarity == 1
            }"
            class="col-lg-12 farm-title"
          >
            <span *ngIf="rarity == 3">EXCLUSIVE VIP NFTs</span>
            <span *ngIf="rarity == 2">ULTRA RARE NFTs</span>
            <span *ngIf="rarity == 1">RARE NFTs</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center pd-t-40" *ngIf="i == 0">
      <span
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
        *ngFor="let background of backgrounds"
      >
        <div *ngFor="let image of totalImages">
          <app-aloha-card
            image="{{ image }}"
            rarity="{{ rarity }}"
            background="{{ background }}"
          ></app-aloha-card>
        </div>
      </span>
    </div>

    <div class="row row-xs justify-content-md-center">
      <div class="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-10">
        <div class="card card-status">
          <div class="row txt-center">
            <div class="col-lg-12">
              <h1 class="txt-apy-box-2">
                ALOHA
                <span *ngIf="pool.erc20Symbol"> / {{ pool.erc20Symbol }} </span>
              </h1>
            </div>
            <div class="col-lg-12">
              <p class="txt-actual">Token To Stake</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-10">
        <div class="card card-status">
          <div class="row txt-center">
            <div class="col-lg-12">
              <h1 class="txt-apy-box-1">
                {{ pool.alohaAmount }} ALOHA <br />
                <span *ngIf="pool.erc20Symbol">
                  {{ pool.erc20Amount }} {{ pool.erc20Symbol }}
                </span>
              </h1>
            </div>
            <div class="col-lg-12">
              <p class="txt-actual">Amount To Stake</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-10">
        <div class="card card-status">
          <div class="row txt-center">
            <div class="col-lg-12">
              <h1 class="txt-apy-box-2">{{ toTime(pool.duration) }}</h1>
            </div>
            <div class="col-lg-12">
              <p class="txt-actual">Time To Stake</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs justify-content-md-center txt-center">
      <div class="col-lg-3 pd-t-40 pd-b-30">
        <div class="btn-demo" *ngIf="!account">
          <button
            class="btn btn-oblong btn-aloha btn-block"
            data-toggle="modal"
            data-target="#walletModal"
          >
            CONNECT YOUR WALLET
          </button>
        </div>

        <div class="btn-demo" *ngIf="account && !pool.erc20Symbol">
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="!allowedAloha && !inStake[pool.address]"
            (click)="approveAloha()"
          >
            APPROVE ALOHA
          </button>
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="allowedAloha && !inStake[pool.address]"
            (click)="simpleStake(rarity)"
          >
            STAKE ALOHA
          </button>
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="inStake[pool.address]"
            [routerLink]="['/collection']"
          >
            ALREADY IN
          </button>
        </div>

        <div class="btn-demo" *ngIf="account && pool.erc20Symbol">
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="!allowedAloha && !inStake[pool.address]"
            (click)="approveAloha()"
          >
            APPROVE ALOHA
          </button>
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="!allowedToken[pool.address] && !inStake[pool.address]"
            (click)="approveToken(pool.address)"
          >
            APPROVE {{ pool.erc20Symbol }}
          </button>
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="
              allowedAloha &&
              allowedToken[pool.address] &&
              !inStake[pool.address]
            "
            (click)="pairStake(pool.address, rarity)"
          >
            STAKE ALOHA / {{ pool.erc20Symbol }}
          </button>
          <button
            class="btn btn-oblong btn-aloha btn-block"
            *ngIf="inStake[pool.address]"
            [routerLink]="['/collection']"
          >
            ALREADY IN
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="slim-mainpanel pd-b-10"></div>

<div class="slim-mainpanel pd-b-40">
  <div class="container">
    <div class="row row-xs justify-content-md-center txt-center">
      <div class="col-lg-7">
        <span>
          You can cancel your stake at anytime. The full amount of tokens will
          be refunded.
          <br /><br />
          At the time of claiming your NFT you will receive the token directly
          in your wallet in addition to your staked tokens minus a 3% fee that
          will be redistributed on the platform.
        </span>
      </div>
    </div>
  </div>
</div>
