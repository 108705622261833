<div
  *ngIf="imageType === 'static'"
  class="aloha-card"
  [routerLink]="id ? ['/card/' + id] : []"
  [ngStyle]="{ cursor: id ? 'pointer' : '' }"
>
  <img class="image" [src]="imageSource" alt="img-mobile" />
  <img class="frame" [src]="frameSource" alt="img-mobile" />
</div>

<div
  *ngIf="imageType === 'animated'"
  class="aloha-card"
  [routerLink]="id ? ['/card/' + id] : []"
  [ngStyle]="{ cursor: id ? 'pointer' : '' }"
>
  <video autoplay loop class="image" alt="img-mobile">
    <source [src]="imageSource" type="video/ogg" />
  </video>
</div>
