import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WalletService } from './services/wallet.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('walletModal') walletModal: ElementRef;

  constructor(private readonly walletService: WalletService) {}

  address: string = null;

  ngOnInit(): void {
    this.walletService.init();

    this.walletService.getAccount()
      .then((account) => {
        this.address = account;
      });
  }

  async connectWithMetamask(): Promise<void> {
    this.address = await this.walletService.connectWithMetamask();
    this.walletModal.nativeElement.click();
  }

  async connectWithWalletConnect(): Promise<void> {
    this.address = await this.walletService.connectWithWalletConnect();
    this.walletModal.nativeElement.click();
  }
}
