<div class="slim-mainpanel">
  <div class="container">
    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>EXCLUSIVE VIP NFTs</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center pd-t-20">
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card image="7" rarity="3" background="1"></app-aloha-card>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-4">
            <div class="btn-demo">
              <a
                href="https://nft.alohadefi.io/pool/3"
                target="_blank"
                class="btn btn-oblong btn-aloha btn-block btn-vip"
              >
                STAKE TO WIN AN EXCLUSIVE VIP NFT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-ultrarare txt-line-ultrarare">
            <span>ULTRA RARE NFTs</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center pd-t-40">
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card image="8" rarity="2" background="1"></app-aloha-card>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card image="9" rarity="2" background="2"></app-aloha-card>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-4">
            <div class="btn-demo">
              <a
                href="https://nft.alohadefi.io/pool/2"
                target="_blank"
                class="btn btn-oblong btn-aloha btn-block btn-vip"
              >
                STAKE TO WIN AN ULTRA RARE NFT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-rare txt-line-rare">
            <span>RARE NFTs</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center pd-t-40">
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card image="10" rarity="1" background="1"></app-aloha-card>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card image="11" rarity="1" background="2"></app-aloha-card>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card image="12" rarity="1" background="3"></app-aloha-card>
        </div>
      </div>

      <div class="col-md-12 pd-b-40">
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-4 pd-b-40">
            <div class="btn-demo">
              <a
                href="https://nft.alohadefi.io/pool/1"
                target="_blank"
                class="btn btn-oblong btn-aloha btn-block btn-vip"
              >
                STAKE TO WIN A RARE NFT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
